import pImg1 from '../images/blog/blog1.png'
import pImg2 from '../images/blog/blog2.png'
import pImg3 from '../images/blog/blog3.png'
import sImgS1 from '../images/blog/blog1.png'
import sImgS2 from '../images/service-single/2.jpg'
import sImgS3 from '../images/service-single/3.png'
import sImgS4 from '../images/service-single/4.jpg'
import sImgS5 from '../images/service-single/5.jpg'
import sImgS6 from '../images/service-single/6.jpg'
import sImgS7 from '../images/blog/blog1.png'
import sImgS8 from '../images/blog/blog2.png'
import sImgS9 from '../images/blog/blog3.png'
import oimg1 from '../images/blog/1.jpeg'
import oimg2 from '../images/blog/2.jpeg'
import oimg3 from '../images/blog/3.jpeg'
import oimg4 from '../images/blog/4.jpeg'
import oimg5 from '../images/blog/5.jpeg'
import oimg6 from '../images/blog/6.jpeg'
import oimg7 from '../images/blog/7.jpeg'
import oimg8 from '../images/blog/8.jpeg'
import oimg9 from '../images/blog/9.jpeg'
import oimg10 from '../images/blog/10.jpeg'
import oimg11 from '../images/blog/11.jpeg'
import oimg12 from '../images/blog/12.jpeg'
import oimg13 from '../images/blog/13.jpeg'
import oimg14 from '../images/blog/14.jpeg'
import oimg15 from '../images/blog/15.jpeg'
import oimg16 from '../images/blog/16.jpeg'
import oimg17 from '../images/blog/17.jpeg'
import oimg18 from '../images/blog/18.jpeg'
import oimg19 from '../images/blog/19.jpeg'
import oimg20 from '../images/blog/20.jpeg'
import oimg21 from '../images/blog/21.jpeg'
import oimg22 from '../images/blog/22.jpeg'
import oimg23 from '../images/blog/23.jpeg'
import oimg24 from '../images/blog/24.jpeg'
import oimg25 from '../images/blog/25.jpeg'
import oimg26 from '../images/blog/26.jpeg'
import oimg27 from '../images/blog/27.jpeg'
import oimg28 from '../images/blog/28.jpeg'
import oimg29 from '../images/blog/29.jpeg'
import oimg30 from '../images/blog/30.jpeg'
import oimg31 from '../images/blog/31.jpeg'
import oimg32 from '../images/blog/32.jpeg'

const blogs = [
    {
        id: "1",
        title: "In Syria: Revolt and Secularity",
        slug: "in-syria-revolt-and-secularity",
        screens: pImg1,
        description: ` The recent protests in several Arab countries have aroused great hope among women. These mass protests were considered at the time the beginning of an awareness, with the final goal being the attainment of a change. These results had been preceded by a phase of several...`,
        author: "randa kassis",
        authorTitle: "randa kassis",
        date: "10/06/2015",
        otherimg: pImg1,
        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "2",
        title: "Turkey: is it still our ally?",
        slug: "turkey-is-it-still-our-ally",
        screens: pImg2,
        description: `Published in Le figaro, 24/09/2014 By Fabien Baussart, Alexandre del Valle, Randa Kassis In order to fight the Islamic State, that totalitarian monstrosity that intends to subdue the Middle East and in the end, the entire world, ten countries in the region have signed the « Jeddah Communique...`,
        author: "randa kassis",
        authorTitle: "politician ",
        date: "24/09/2014",
        otherimg: pImg2,
        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "3",
        title: "Syria’s Identity: Variety in Minorities",
        slug: "syrias-identity-variety-in-minorities",
        screens: pImg3,
        description: `In the face of the fierce battle that the regime is waging against its people, it has become apparent that the opposition leadership is unable to face this movement. There are many reasons for this...`,
        author: "randa-kassis",
        authorTitle: "politician",
        date: "02/08/2012",
        otherimg: pImg3,
        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "3",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story",
        screens: pImg3,
        description: `Photo 1, died 26 February 2025.    Photo 2, died 24 June 1990
I have no right to weep for you, for I was never by your side. I have no right to speak of pain, for I was always far from you.
I cannot even ask for your forgiveness, for you have departed this life, and there is no life for you beyond it. How, then, can I console myself, when I have spent years burdened by the bitterness of self-reproach since my father’s passing? I left just days before his death and never stayed. Was I fleeing from grief, from finality? Or was I simply placing myself above all else? I do not know…
`,
        des2: "But I do know that I have done it again—with you. I returned to Syria a month before your death, yet I left you once more. Was it something in my blood, an instinct to escape? Or do I carry a detachment that even I do not fully understand? Perhaps… I will find no answer until my own final moments—only then will I know what you both felt when your children were absent.",
        des3: "And I do not know why I write these words. Am I confessing my failures, declaring to the world that I was a daughter who never truly thought of you? Or am I simply trying to release the weight that chokes me? Perhaps I am just someone incapable of keeping emotions locked within, needing to declare to all: I am who I am.",
        des4: "Nothing has stripped me of my strength as your absence has. Nothing has left me so exposed to my sorrow as your passing has. I know you devoted yourself to others, but I am certain that our distance caused you sadness—even if you never admitted it, even if you refused to acknowledge it. And I know, too, that we all disappointed you. You had every right to feel that way. For had my own daughter done to me what I did to you, I would have felt the same.",
        des5: "At night, when I struggle to sleep despite the breathing exercises and techniques that no longer work, my mind drifts far away. I stare into the void, drowning in memories of all those I have loved—you among them. And I admit that I was harsh with you—or perhaps harshness has simply become a part of me. I search for you, for all those I have loved and lost, within the corridors of my mind, which still clings to your memory and theirs. No doubt you are present now with greater intensity because your departure is the most recent, while my memory pushes away those who left long ago. I wonder in silence: Can you hear me, wherever you are? But reason whispers back: There is no one there to listen.",
        des6: "All I have left of you are memories and the image of your face in my mind, just like those who passed before you. I will carry your memory like an ember in my heart—painful, yet the only thing that keeps me close to you. No other world will reunite us, but you will remain alive within me, an eternal presence that will stay with me for the rest of my days.",
        des7: "My only solace is knowing that you are free from pain.",
        des8: "And so, I write—not to seek redemption, nor to be absolved of what I cannot change, but simply because silence is unbearable.",

        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg1,
        otherimg2: oimg2,
        otherimg3: oimg3,
        comment: "35",
        blClass: "format-standard-image",
    },
    {
        Id: '1',
        screens: sImgS1,   
        title: 'Opposition Leader: Resolution of Syrian Crisis Should Be Kick-Started in Sochit',
        slug: 'Opposition-Leader',
        author: 'john doe',
        date: '02/05/2024',
        description: `Sputnik          30/1/2018
The Russia-hosted Syrian National Dialogue Congress in Sochi may pave the way to the resolution of the Syrian crisis, Randa Kassis, the leader of the “Astana platform” of the Syrian opposition, has told Sputnik, highlighting the necessity of the creation of the constitutional commission during the ongoing summit to facilitate the peace process.`,
        des2: `Those who have come to the Syrian National Dialogue Congress in the Russian resort city of Sochi are motivated by patriotism and responsibility for the future of their country, Randa Kassis, the leader of the “Astana platform” of the Syrian opposition, told Sputnik Arabic, slamming those who snubbed the summit.`,
        des3: `“Anyone who refused to participate in the Sochi congress is irresponsible,” the politician highlighted.`,
        des4:`According to Kassis, all the members of her delegation understand that the Syrian crisis could not be resolved under the aegis of the United States. The Syrians have to kick-start the process themselves, here, in Sochi, she stressed.`,
        des5:`
“We must continue to work despite numerous difficulties faced by the Syrian opposition,” the politician said. “We must do everything to ensure the success of the Sochi talks.”`,
        des6:`
Kassis underscored that President Vladimir Putin's initiative to hold the Syrian National Dialogue Congress in Sochi is an important step, which, unfortunately, was not accepted by some political figures.`,
        des7:`“If Vladimir Putin initiated something, it means not just the intention to do something, but the implementation of the objectives,” she pointed out. “Therefore, I pin my hopes on the event and this is why we arrived in Sochi.”`,
        des8:`
The politician emphasized that she had made efforts to gather as many representatives of the Syrian opposition as possible to form a working group. “We need to start finding the way out of the crisis immediately. I believe that it is impossible without the creation of a new constitution,” she noted.`,
        des9:`
Speaking to Sputnik, Kassis shed light on the Astana platform’s plans and program. The politician emphasized the necessity to create a “constitutional commission” within the framework of the negotiations.`,
        des10:`
“We need to start working on a new Syrian constitution,” she said. “We have prepared our own draft constitution, on which we have spent a year working on.”`,
        des11:`According to the politician one of the most important issues concerning the future Syrian constitution is the maintenance of a secular state: “To clarify this issue I offered the following [definition]: the secularism is defined by the state’s neutrality to religious or non-religious beliefs of a Syrian citizen,” she said, “Beliefs could be not only religious, it could be views or a position and a person should have freedom [to express them].”`,
        des12:`
The present Syrian constitution offers four options to define a Syrian citizen’s religious beliefs: Sunni Muslim, Druze, Orthodox or Catholic Christian. Kassis explained that for ordinary Syrians the matters of marriages and inheritance are closely connected with their religious beliefs and existing state laws.
`,
        des13:`
She noted that “in order to overcome the potential difficulties” she proposes “to introduce the fifth category — “a Syrian citizen” — without any religious references.`,
        des14:`“It is necessary to give the Syrians the freedom of choice. A person may prefer to live in an exclusively secular legal field,” Kassis suggested.`,
        des15:`On January 30 the Syrian national dialogue congress officially opened in Sochi. The summit has brought together various opposition and pro-governmental forces, as well as representatives of the country’s numerous ethnic and religious groups in order to facilitate the peace process with the focus on the Syrian constitution and elections.`,
        des16:`
The UN Security Council permanent members as well as Egypt, Iraq, Jordan, Kazakhstan, Lebanon and Saudi Arabia were also invited to participate in the event as observers. However, a number of states including the US and France, refused to attend, as did the Syrian Negotiation Commission (SNC) opposition group.`,
        des17:``,
        icon: 'flaticon-credit',
        ssImg1: sImgS1,
        ssImg2: sImgS1,
    },
    {
        Id: '2',
        screens: sImgS2,
        title: 'Kassis meets Rayburn, US Special Envoy for Syria',
        slug: 'kassis-meets-rayburn',
        author: 'john doe',
        date: '02/05/2024',
        description: `17/03/2019

Randa Kassis, President of the Astana platform, and Joel D. Rayburn, US Special Envoy for Syria and Deputy Assistant Secretary of State, met this Saturday on March 16th 2019 in Paris. During their discussion they found a common vision on the political process needed in Syria.

They also discussed the need to support the constructive and balanced approach of the new UN Special Envoy Geir Pedersen.`,
        des2: '.',
        des3: '',
        icon: 'flaticon-job',
        ssImg1: sImgS2,
        ssImg2: sImgS2,
    },
    {
        Id: '3',
        screens: sImgS3,
        title: 'Kassis meets Beibut Atamkulov, Minister of Foreign Affairs of Kazakhstan',
        slug: 'Kassis-meets-Beibut',
        author: 'john doe',
        date: '02/05/2024',
        description: `Kassis meets Beibut Atamkulov, Minister of Foreign Affairs of Kazakhstan
26-02-2019

Meeting between Beibut Atamkulov, Minister of Foreign Affairs of Kazakhstan, Randa Kassis, President of the Astana Platform, and Fabien Baussart, President of CPFA, in the presence of Deputy Minister Roman Vassilenko and Aidarbek Tumatov, Director of the Middle East. 
They had a friendly and fruitful discussion, where they spoke about the crisis in Syria and the need to achieve peace negotiated in respect for plurality.
Fabien Baussart noted the crucial and historic role of President N. Nazarbayev in the fight against nuclear non-proliferation since the 1990s.
Randa Kassis thanked President Nazarbayev and Kazakhstan for agreeing to initiate political talks in 2015, after the appeal she launched, and for hosting negotiations at the end of 2016.
Kazakhstan’s contribution to peace has been paramount.`,
        des2: '',
        des3: '',
        icon: 'flaticon-team-work',
        ssImg1: sImgS3,
        ssImg2: sImgS3,
    },
    {
        Id: '4',
        screens: sImgS4,
        title: 'A “road map” to peace.. will be presented over the next few weeks',
        slug: 'road-map-to-peace',
        author: 'john doe',
        date: '02/05/2024',
        description: `The Syrian opposition on Monday presented a “road map” to peace, including steps to be taken ahead of an election and guidelines for a plan to write the country’s post-war constitution. The document, drawn up by representatives of the opposition invited to Rome by the Catholic Sant’Egidio community, will be presented to key players in the conflict — from Russia to Turkey and the EU — over the next few weeks.`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-bus',
        ssImg1: sImgS4,
        ssImg2: sImgS4,
    },
    {
        Id: '5',
        screens: sImgS5,
        title: 'Trump’s son meets Randa Kassis',
        slug: 'trumps-son-meets-randa-kassis',
        author: 'john doe',
        date: '02/05/2024',
        description: `Press Tv               26/11/2016

Reports suggest Trump’s eldest son, Donald Trump Junior, met the pro-Russia Syrian politician Randa Kassis nearly a month ahead of the November 8 election.

`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS5,
        ssImg2: sImgS5,
    },
    {
        Id: '6',
        screens: sImgS6,
        title: 'Is there any hope for the Syria talks?',
        slug: 'is-there-any-hope-for-the-syria-talks',
        author: 'john doe',
        date: '02/05/2024',
        description: `      30/01/2016


Opposition groups held multiple meetings in Riyadh to decide who would attend the talks. And the international community repeatedly debated the fate of Syrian President Bashar al-Assad, all in anticipation of negotiations that were scheduled to begin in January.

Those talks were delayed once but have now begun. But without main members of the opposition.
`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS6,
        ssImg2: sImgS6,
    },
    {
        Id: '7',
        screens: sImgS7,
        title: 'In Syria: Revolt and Secularity',
        slug: 'in-syria-revolt-and-secularity',
        author: 'john doe',
        date: '02/05/2024',
        description: ` The recent protests in several Arab countries have aroused great hope among women. These mass protests were considered at the time the beginning of an awareness, with the final goal being the attainment of a change. These results had been preceded by a phase of several...`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS7,
        ssImg2: sImgS7,
    },
    {
        Id: '8',
        screens: sImgS8,
        title: 'Turkey: is it still our ally?',
        slug: 'turkey-is-it-still-our-ally',
        author: 'john doe',
        date: '02/05/2024',
        description: `Published in Le figaro, 24/09/2014 By Fabien Baussart, Alexandre del Valle, Randa Kassis In order to fight the Islamic State, that totalitarian monstrosity that intends to subdue the Middle East and in the end, the entire world, ten countries in the region have signed the « Jeddah Communique...`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS8,
        ssImg2: sImgS8,
    },
    {
        Id: '9',
        screens: sImgS9,
        title: 'Syria’s Identity: Variety in Minorities',
        slug: 'syrias-identity-variety-in-minorities',
        author: 'john doe',
        date: '02/05/2024',
        description: `In the face of the fierce battle that the regime is waging against its people, it has become apparent that the opposition leadership is unable to face this movement. There are many reasons for this...`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS9,
        ssImg2: sImgS9,
    },
    {
        id: "3",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story0",
        screens: pImg3,
        description: `I was not born a rebel, but rebellion found me early. As a child, I was inquisitive—curious about the world, about my body, about the invisible rules that dictated how I should exist within it. I remember being five or six years old, exploring myself with innocent curiosity, only to discover something I had been conditioned to believe was impure. My instinct was to cleanse, to rid myself of what I thought was dirt. But that act, simple and instinctive, was also my first conscious rejection of an imposed taboo.
`,
        des2: "Later, as an adolescent, I repeated the act—not out of naivety, but with full intention. I had no desire for my hymen to be lost through sexual intercourse, for it to be a symbol of virtue or a transaction. I despised its very concept.",
        des3: "Even before I understood rebellion in an intellectual sense, I had already lived it. Algeria shaped me in ways I could never have anticipated. There, that I grasped the nature of power—how it operates, how it can be resisted. I had moved from a relatively sheltered world into an environment where survival meant more than just existing; it required assertion, defiance, a willingness to stand one’s ground. I was not born a fighter, but Algeria made me one.",
        des4: "The First Battle: Defying Power",
        des5: "My first physical confrontation was a defining moment. It happened in the schoolyard, a place that, for many children, is merely a setting for games and lessons, but for me, it became an early battlefield. A group of boys, emboldened by their own sense of entitlement, sought to intimidate me. I refused to shrink. When one of them made his move, I retaliated. I knocked him to the ground, but that act of defiance came at a price. The boys turned on me, pelting me with stones.",
        des6: "The pain was real, but it wasn’t what left a mark on me. What I remember most vividly isn’t the pain—it’s the realisation that fear is a weapon. And I could choose not to carry it. I stood there, battered but unbroken, and something shifted within me. I knew that mere defence was not enough. I had to go on the offensive.",
        des7: "I charged toward one of the boys, grabbed a stone larger than the ones they had thrown, and raised it high. For a brief second, I hesitated. Did I truly intend to strike? Or was it enough that they believed I would? I will never know the answer. What I do know is that in that moment, the balance of power changed. I saw fear flicker in their eyes, and with it, I understood something fundamental: power is an illusion, sustained only by submission. I had refused to submit.",
        des8:`That moment did not just shape how others saw me; it shaped how I saw myself. It changed my place in that school. The girls, who had once been passive observers, now looked to me for protection. The boys, who had sought to put me in my place, now knew I would not be tamed. But beyond that, something deeper had taken root in me. I understood, in a visceral way, that authority—whether in the form of a schoolyard bully or a greater system—was only as strong as our willingness to accept it. That lesson stayed with me. Over time, I distanced myself from all girls who had once followed the script written for them. I questioned religion, society, morality—every structure that sought to define my existence. My father, an agnostic, had instilled in me the value of independent thought, and I took that lesson to its extreme. I rejected all imposed beliefs, seeing them as viruses infecting human minds. My disdain for authority extended beyond the personal to the political, and my involvement in Syria’s revolution was not just a political stance—it was an extension of who I had become.`,
        // des9:`Algeria was my second birth. It was there that I first saw myself clearly, as though I had been shedding layers all along, waiting for the moment when my true nature would finally emerge. Perhaps my first physical fight with the boys at school had been the catalyst for this change. In that moment, I understood—as did everyone else—that the quiet Syrian Randa no longer existed. Fear had left her, and no authority, no God, could intimidate her again`,
        // des10:`Since childhood, I had wrestled with life’s most perplexing questions: What is life? Why do we cling to it so fiercely? Is it driven by our egocentrism, or merely the cowardice to sever what procreation has set in motion?`,
        // des8:``,

        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg4,
        otherimg2: oimg5,
        otherimg3: oimg6,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "15",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story1",
        screens: pImg3,
        description: `Algeria was my second birth. It was there that I first saw myself clearly, as though I had been shedding layers all along, waiting for the moment when my true nature would finally emerge. Perhaps my first physical fight with the boys at school had been the catalyst for this change. In that moment, I understood—as did everyone else—that the quiet Syrian Randa no longer existed. Fear had left her, and no authority, no God, could intimidate her again.
`,
        des2: "Since childhood, I had wrestled with life’s most perplexing questions: What is life? Why do we cling to it so fiercely? Is it driven by our egocentrism, or merely the cowardice to sever what procreation has set in motion?",
        des3: "Returning to Syria: A Turbulent Teenage Rebel",
        des4: "When I returned to Syria at 12,I rebelled against everyone—authority, the police, and especially the political system. I refused to be enrolled in the Shabiba (Ba’ath Youth), rejecting the indoctrination and slogans forced upon students. I never accepted to be just a number in this country, a cog in the system that dictated how we should think, act, and exist.",
        des5: "Since the age of fourteen, I had engaged my friends in Damascus in debates over the existence of God. Back then, no one dared question religion or the notion of a creator. They labelled me a heretic, believingthat me disregard for social and political order was a direct result ofmy rejection of both God and authority.",

        des6: "At fifteen, my rebellion deepened. I made acquaintances with French employees at the French Cultural Center, where I found an intellectual and cultural connection that was absent amongmy own friends. At the same time, I traveled across Syria with friends my age, engaging in endless debates about God, religions, and the taboos that society tried to impose on us.",
        des7: "After my baccalaureate, I went to Germany to study, but I quickly realised that its structured, rigid, and highly organised society did not suit me. It felt too constrained, too predictable, too confining.",
        des8:`There, I met refugees who had fled war—Lebanese, Palestinians—and for the first time, I truly connected with the full scope of Palestinian suffering, beyond the fragments I had heard from my mother, who worked at UNRWA in Damascus, or the stories of resistance I had encountered at school.`,
         des9:`I went to Paris, the city I had first fallen in love with at 10 years old, a place that had left an imprint on my mind. Paris had always felt like home—its intellectual vibrancy, its artistic defiance, its embrace of free thought resonated with the spirit of rebellion I carried within me.`,

        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg7,
        otherimg2: oimg8,
        otherimg3: oimg9,
        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "16",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story2",
        screens: pImg3,
        description: `Yet, with time, I found myself caught between two forces—one that compelled me to act, to create, to fight for change, and another that whispered the absurdity of it all. I was passionate about causes, about ideas, about possibilities, but I also saw the futility in human existence. I oscillated between engagement and nihilism, between building something new and accepting that nothing truly lasts. Death was not something I feared; rather, I saw it as an inevitable release, perhaps even a form of salvation. And maybe that’s why I sought movement, why stability never suited me—I needed to chase moments of fascination before they lost their meaning.
`,
        des2: "I met an Israeli young woman, the same age as me, and we became very close. Her life was a tragedy in itself—a mother who had survived the Shoah, a father who refused to acknowledge her, and the wreckage of two failed love stories. Over time, life became increasingly unbearable for her, and depression consumed her until, one day, she chose to surrender herself to death.",
        des3: "The police advised me not to see her body, urging me to keep the memory of her as the beautiful woman I had known. But through her, I began to truly understand both miseries—Palestinian and Israeli.",
        des4: "Never once did I feel a sense of satisfaction. I drifted from psychology to painting, passed through writing, and eventually found myself in politics—never settling, always searching, as if no path could contain me.",
        des5: "Painting-a style of life",

        des6: "At the beginning of 1994, I started painting. My brother, Jean—one of the greatest contemporary painters in my eyes—was my first inspiration. I was drawn to his creative style, captivated by the raw intensity of his work.",
        des7: "For me, painting was never just a craft; it was an act of defiance—a rebellion against imposed narratives and the rigid expectations of what a woman should be. My work was raw, unfiltered, unapologetic. From the very beginning, my art was a form of resistance, a means to challenge the world as I saw it, to reject the suffocating conventions that sought to dictate how a woman, a body, or an artist should be perceived.",
        des8:`The female form has always been central to my work, but not in the way it’s been commodified for centuries. I refused to depicted it as a passive object of beauty or desire. To me, the body is a battlefield—a paradox, both a prison and a tool of liberation. My brushstrokes were never meant to comfort; they were meant to provoke, to unearth something unsettling, something that forces the viewer to confront their own gaze, their own biases.`,
         des9:`I never cared for perfect symmetry or predictable aesthetics. I paint to merge chaos with structure, to blend dream and reality until the line between them collapses. My canvases don’t tell linear stories—they vibrate, they pulse with contradictions. Critics have said my work feels like music—that it carries its own rhythm. I like that idea. Because art, for me, has always been about breaking silence, shattering complacency.`,
         des10:`I’ve exhibited my work in different places, seen people stare, question, sometimes even recoil. And I embrace that. Art should be unsettling. It should gnaw at something inside you, push you to see beyond the surface. `,

        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg10,
        otherimg2: oimg11,
        otherimg3: oimg12,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "17",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story3",
        screens: pImg3,
        description: `1997–2007: A Life of Art, Motherhood, and Fighting Spirit`,
        des2: "Between 1997 and 2007, I balanced motherhood, art, and self-discipline. I had separated from my daughter’s father when she was almost two years old. Until she was 14, when the Syrian revolt began, my life was intertwined with hers.",
        des3: "I drove her to her activities,to seefriends—we traveled together, went out often, even with my own friends. I was never absent from her life. But at the same time, I pursued my own path, refusing to let motherhood define me entirely.",
        des4: "Along side this, I trained in kickboxing and Muay Thai—not just for self-defence, but for self-control. The disciplineI gained in combat extended beyond the ring, shaping how I approached life itself.",
        des5: "Seeking another artistic outlet, I enrolled at “Cours Florent,” immersing myself in performance and storytelling. Theatre gave me a voice—a structured yet liberating way to channel my thoughts and emotions. It was through this experience that I later began writing for Al Hiwar Al Mutamadin.",

        des6: "2007: The Turning Point—My Atheist and Political Activism",
        des7: "In 2007, I began writing to challenge religious and political dogmas in the Arab world. My knowledge of psychology and anthropology helped me dissect religions and cultures, which became the foundation of my first articles, writings, and TV program.",
        des8:`I wrote publicly, encouraged by my elder brother Nabil, who believed in me and in my ability to write. Perhaps I had inherited this gift from my father—the ability to dismantle the dogmas that governed so many aspects of life in the Arab world.`,
         des9:`At the time, platforms like Al Hiwar Al Mutamadin (Civil Discourse) were among the few spaces where secularists, atheists, and free thinkers could engage in meaningful discussions without censorship. Through these debates, I connected with like-minded individuals such as Kacem El Ghazzali, Waleed Al-Husseini, and many others who, like me, waged an intellectual rebellion against the constraints imposed by religion and tradition.`,
         des10:`My activism wasn’t confined to words—it was action. I helped ex-Muslims escape persecution—providing financially and logistical support, and at times, offering them refuge when they had nowhere else to turn.`,

        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg13,
        otherimg2: oimg14,
        otherimg3: oimg15,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "18",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story4",
        screens: pImg3,
        description: `Political Fight: A Struggle for Secularism and Inclusion`,
        des2: "Yet, entring politics forced me toreorganise my ideas—for I knew that most people needed some structures to order their lives. Thus emerged my quest to find a balance—one that respected individual freedom while ensuring enough structure to maintain order, without stripping away personal identity or crushing the human desire for self-fulfilment.",
        des3: "The Arab Spring and My Exclusion from the Opposition",
        des4: "In 2010, I played a key role in commentating and writing about the Tunisian revolution. As the Arab Spring gathered momentum, I felt compelled to return to Syrian political life. I co-founded, alongside other opponents, the secular democratic forces against Bashar al-Assad, which led to my position in the Syrian National Council (SNC).",
        des5: "However, my outspoken warnings about the rise of Radical Islamists in Syria and Muslim fundamentalists within the opposition made me a target. By May 2012, I was expelled from the SNC, cast aside by the very movement I had helped build. Many within the Syrian opposition, including former allies, actively worked against me, seeking to silence my voice and remove me from political life. My unwavering secular stance had made me an outsider, but I refused to retreat.",

        des6: "A New Political Vision: Building a Pluralistic Future",
        des7: "During this turbulent period, my life took botha personal and political turn. I met my love,Fabien Baussart, who would later become my husband and a partner in political initiatives. Together, we launched multiple political projects to counter the forces that had excluded me.",
        des8:`Determined to continue my fight for a secular, pluralistic Syria, I established the Movement for a Pluralistic Societywith the help of Fabien after my exclusion from both the SNC and the secular democratic forces. This was my way of ensuring that the principles of secularism, democracy, and coexistence did not disappear amid the chaos of war and extremism.`,
         des9:`One of the key political initiatives I spearheaded was the Astana Platform, which I launched with Fabien in 2015 afterrequestingthat the President of Kazakhstanestablish a spaceto unitemoderate Syrian opposition figures.`,
         des10:`I participated in the 2016 Geneva Peace Talks as part of the Moscow/Astana groups, serving as Co-President alongside Qadri Jamil in the Syrian secular and democratic opposition delegation.`,
        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg16,
        otherimg2: oimg17,
        otherimg3: oimg18,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "19",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story5",
        screens: pImg3,
        description: `Once again, both allies and enemies conspired to exclude me from the Geneva negotiations in 2017. However, Fabien and I responded by launching a new initiative to establish a constitutional committee. This initiative was made possible with the support of former Italian Foreign Minister Giulio Terzi and former Turkish Foreign Minister Yaşar Yakış, along with the collaboration of Syrian and French constitutionalists.`,
        des2: "On 13 January 2018, I, along with other members of the Astana Platform, participated in the Syrian National Congress as President of the Astana Platform. There, I emphasized the urgency of creating a constitutional committee to facilitate Syria’s peace process. The proposal was later ",
        des3: "adopted by the UN and the Astana Troika (Russia, Iran, and Turkey), marking a significant step toward a political resolution.",
        des4: "Fighting for Secularism Against Political Exclusion",
        des5: "My journey in Syrian politics has been marked by exclusion, betrayal, and constant battles—not just against the Assad regime, but also against factions within the opposition that sought to erase secular voices from the movement.",

        des6: "Yet, despite their attempts to silence me, I remained unwavering. I created new platforms, new alliances, and alternative pathways to keep the secular, democratic vision for Syria alive. My political fight was never about seeking a position or personal recognition—it was and remains a struggle to defend a Syria where secularism and pluralism are not erased by either authoritarianism or fundamentalism.",
        des7: "Legacy of Resistance and Political Survival",
        des8:`The political landscape remains treacherous, but survival is its own form of resistance. While many of my former allies turned against me, I continue to shape discussions, influence policy, and push forward alternatives to religious and authoritarian rule.`,
         des9:`Exclusion did not weaken me—it strengthened my resolve. In a war where voices are either bought, silenced, or erased, mine standsdefiant.`,
        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg19,
        otherimg2: oimg20,
        otherimg3: oimg21,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "20",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story6",
        screens: pImg3,
        description: `Looking Back: The Unyielding Fight for Free Thought and Meaning`,
        des2: "Looking back, my journey has always been about questioning the unquestionable, about pushing against the boundaries imposed by both religion and politics. And while the landscape has shifted in many ways, the fight for free thought in the Arab world remains as urgent as ever.",
        des3: "Beyond my political work, I devoted myself to animal welfare. In Porto Heli, Fabien and I managed a shelter for stray animals, providing healthcare, sterilisation, and rehoming efforts. The fight for human liberation and the fight for animal welfare were, in my eyes, intertwined—both driven by a desire to challenge the structures that perpetuate suffering.",
        des4: "With Fabien, we rescued many pets from the streets, animals that had been abandoned or left to fend for themselves. They became our cherished companions, each one carrying a story of survival.",
        des5: "AD-HOC Organistion",

        des6: "In 2016, I co-organised two major conferences. The first, held in Brussels, was titled The Roots of Terrorism: Secularism as the Solution and brought together influential voices such as the Syrian poet Adonis, the Egyptian thinker Sayyid Al-Qimni, and Ensaf Haidar, wife of the imprisoned Saudi blogger Raif Badawi. The second, later that year, tackled the subject of Islamic Fascism, featuring speakers like Hamed Abdel-Samad and the Moroccan philosopher Said Nachid. These events were not just about discussion—they were an assertion of the presence of Arab secularists in a region where they are often silenced..",
        des7: "Loss, Grief, and the Search for Meaning",
        des8:`Since my withdrawal from political life in 2019 and the loss of my beloved dog, Nietzsche, I plunged into a deep state of grief. Nietzsche was more than just a pet; he was an exceptional companion. He possessed an extraordinary sensitivity to music and a remarkable intelligence. His presence was a silent symphony, an extension of the philosopher whose name he bore.`,
         des9:`To ease my sorrow, I delved into Roger Penrose’s hypothesis of “quantum consciousness”. Yet, as an atheist, I struggled to reconcile my grief with any notion of continuity after death. It felt like a desperate attempt to bridge the gap between loss and logic. I remember vividly that he passed away just one day before Queen Elizabeth—a coincidence that felt both meaningless and strangely poetic.`,
         des10:`My path has always spiraled inward, rejecting simplicity and craving unfiltered truth. This contradiction within me—my relentless pursuit of change despite my belief in life’s futility—defines who I am. Perhaps the end is the moment peace arrives. Perhaps that is why I never settle, always seeking to escape life’s monotonous routine.`,
         des11:`Occasionally, life would surprise me, overwhelming me with its fleeting beauty. In those moments, I felt the whisper of freedom—a breeze that stirred me, as if chasing an elusive fragrance. I longed to immerse myself in its breath, to feel it brush my face. Yet even in those flashes of vitality, life revealed itself to me as “Thanatos,” a kaleidoscope of memories and meanings intertwined with the faces of the departed. The image of my beloved Nietzsche would often emerge, igniting an ache that nestled deep within my heart.`,
        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg22,
        otherimg2: oimg23,
        otherimg3: oimg24,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "21",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story7",
        screens: pImg3,
        description: `The Contradiction That Defines Me`,
        des2: "Since adolescence, I had embracednihilism—living each day as it came, indifferent to the future. But the birth of my daughter stirred something imperceptible within me—an unseen force that subtly tempered my once unrestrained defiance.",
        des3: "In those rare moments of stillness, I would catch my own reflection in her eyes and think, “What if I were to disappear all of a sudden?” It was an uninvited thought, yet one that recurred often enough to become part of me. After all, my daughter had no one but me.",
        des4: "My profound love and fierce attachment to her inevitably forced me to reconsider my anarchic tendencies—not to abandon them, but at least to rein in the chaos within. And yet, I still savoured the taste of disorder—the raw, untamed freedom that refused to be buried within me.",
        des5: "The Absurdity of Emotions and the Hierarchy of Beings",

        des6: "I recognise the absurdity of emotions—love, fear, joy, grief—but I also saw their fleeting intensity as the fabric of life’s meaning, even if that meaning was illusory.",
        des7: "Humans, I thought, were burdened by their awareness of life’s absurdity, suffering more profoundly than other creatures who simply existed in harmony with nature. This is why I saw animals as superior to humans—free fromexistential dread, they lived in harmony with nature.",
        des8:`My thoughts often wandered toward reordering the hierarchy of existence. I would elevate dogs to the pinnacle, seeing in them a purity and harmony with nature that humans had long forsaken. If I could, I would burn the gods and uproot the virus of religion from human minds.  If I had the power, I would sterilise humanity as I did my stray animals—not out of cruelty, but mercy.Like them, humans are trapped in a cycle of suffering they are too blind to break. In Greece, I spared strays the misery of endless reproduction; if only humans could be pared from their own self-inflicted torment.`,
        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg25,
        otherimg2: oimg26,
        otherimg3: oimg27,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        id: "22",
        title: "A rebel at heart: My Story",
        slug: "a-rebel-at-heart-my-story8",
        screens: pImg3,
        description: `A Life Without Meaning, Yet Full of Contradiction`,
        des2: "Life, to me, was a poorly scripted tragedy—its characters stumbling through their lines, obliviousto the absence of direction, climax, or resolution.The scenes unfolded haphazardly, devoid of meaning, and the audience—humanity—was too preoccupied to question the chaos. I watched this performance with quiet indifference, as though seated in the front row of a tragicomic play. Humans, mere puppets, marched unthinking towards their fates, oblivious to the strings that bound them.",
        des3: "On one hand, I had no desire to reform the world, finding solace in my freedom to move untethered, unshackled by dogma or the rigidity of systems. On the other hand, I work tirelessly and passionately to make a difference—whether striving to improve the conditions for animals or seeking solutions for Syria.",
        des4: "This paradox defines me—a restless spirit, torn between accepting life’s futility and a fierce determination to leave my mark on it.",
        des5: "Moving Forward, Without Illusions",

        des6: "Today, I continue to live in that contradiction.I advocate for change while questioning its worth. I embrace the temporary, knowing that permanence is an illusion. I do not believe in an ideal world, nor do I seek one. Instead, I move forward, navigating the currents of life much like I always have—without illusions, without submission, and without regret.",
        author: "randa-kassis",
        authorTitle: "politician",
        date: "25 Sep 2023",
        otherimg1: oimg28,
        otherimg2: oimg29,
        otherimg3: oimg30,


        comment: "35",
        blClass: "format-standard-image",
    },
    {
        Id: '1',
        screens: sImgS1,
        title: 'Opposition Leader: Resolution of Syrian Crisis Should Be Kick-Started in Sochit',
        slug: 'Opposition-Leader',
        author: 'john doe',
        date: '02/05/2024',
        description: `Sputnik          30/1/2018

The Russia-hosted Syrian National Dialogue Congress in Sochi may pave the way to the resolution of the Syrian crisis, Randa Kassis, the leader of the “Astana platform” of the Syrian opposition, has told Sputnik, highlighting the necessity of the creation of the constitutional commission during the ongoing summit to facilitate the peace process.

Those who have come to the Syrian National Dialogue Congress in the Russian resort city of Sochi are motivated by patriotism and responsibility for the future of their country, Randa Kassis, the leader of the “Astana platform” of the Syrian opposition, told Sputnik Arabic, slamming those who snubbed the summit.

“Anyone who refused to participate in the Sochi congress is irresponsible,” the politician highlighted.

According to Kassis, all the members of her delegation understand that the Syrian crisis could not be resolved under the aegis of the United States. The Syrians have to kick-start the process themselves, here, in Sochi, she stressed.

“We must continue to work despite numerous difficulties faced by the Syrian opposition,” the politician said. “We must do everything to ensure the success of the Sochi talks.”

Kassis underscored that President Vladimir Putin’s initiative to hold the Syrian National Dialogue Congress in Sochi is an important step, which, unfortunately, was not accepted by some political figures.
“If Vladimir Putin initiated something, it means not just the intention to do something, but the implementation of the objectives,” she pointed out. “Therefore, I pin my hopes on the event and this is why we arrived in Sochi.”

The politician emphasized that she had made efforts to gather as many representatives of the Syrian opposition as possible to form a working group. “We need to start finding the way out of the crisis immediately. I believe that it is impossible without the creation of a new constitution,” she noted.

Speaking to Sputnik, Kassis shed light on the Astana platform’s plans and program. The politician emphasized the necessity to create a “constitutional commission” within the framework of the negotiations.

“We need to start working on a new Syrian constitution,” she said. “We have prepared our own draft constitution, on which we have spent a year working on.”

According to the politician one of the most important issues concerning the future Syrian constitution is the maintenance of a secular state: “To clarify this issue I offered the following [definition]: the secularism is defined by the state’s neutrality to religious or non-religious beliefs of a Syrian citizen,” she said, “Beliefs could be not only religious, it could be views or a position and a person should have freedom [to express them].”
The present Syrian constitution offers four options to define a Syrian citizen’s religious beliefs: Sunni Muslim, Druze, Orthodox or Catholic Christian. Kassis explained that for ordinary Syrians the matters of marriages and inheritance are closely connected with their religious beliefs and existing state laws.

She noted that “in order to overcome the potential difficulties” she proposes “to introduce the fifth category — “a Syrian citizen” — without any religious references.

“It is necessary to give the Syrians the freedom of choice. A person may prefer to live in an exclusively secular legal field,” Kassis suggested.

On January 30 the Syrian national dialogue congress officially opened in Sochi. The summit has brought together various opposition and pro-governmental forces, as well as representatives of the country’s numerous ethnic and religious groups in order to facilitate the peace process with the focus on the Syrian constitution and elections.

The UN Security Council permanent members as well as Egypt, Iraq, Jordan, Kazakhstan, Lebanon and Saudi Arabia were also invited to participate in the event as observers. However, a number of states including the US and France, refused to attend, as did the Syrian Negotiation Commission (SNC) opposition group.
 `,
        des2: ``,
        des3: ``,
        icon: 'flaticon-credit',
        ssImg1: sImgS1,
        ssImg2: sImgS1,
    },
    {
        Id: '2',
        screens: sImgS2,
        title: 'Kassis meets Rayburn, US Special Envoy for Syria',
        slug: 'kassis-meets-rayburn',
        author: 'john doe',
        date: '02/05/2024',
        description: `17/03/2019

Randa Kassis, President of the Astana platform, and Joel D. Rayburn, US Special Envoy for Syria and Deputy Assistant Secretary of State, met this Saturday on March 16th 2019 in Paris. During their discussion they found a common vision on the political process needed in Syria.

`,
        des2: 'They also discussed the need to support the constructive and balanced approach of the new UN Special Envoy Geir Pedersen.',
        des3: '',
        icon: 'flaticon-job',
        ssImg1: sImgS2,
        ssImg2: sImgS2,
    },
    {
        Id: '3',
        screens: sImgS3,
        title: 'Kassis meets Beibut Atamkulov, Minister of Foreign Affairs of Kazakhstan',
        slug: 'Kassis-meets-Beibut',
        author: 'john doe',
        date: '02/05/2024',
        description: `Kassis meets Beibut Atamkulov, Minister of Foreign Affairs of Kazakhstan
26-02-2019

Meeting between Beibut Atamkulov, Minister of Foreign Affairs of Kazakhstan, Randa Kassis, President of the Astana Platform, and Fabien Baussart, President of CPFA, in the presence of Deputy Minister Roman Vassilenko and Aidarbek Tumatov, Director of the Middle East. 
They had a friendly and fruitful discussion, where they spoke about the crisis in Syria and the need to achieve peace negotiated in respect for plurality.

`,
        des2: 'Fabien Baussart noted the crucial and historic role of President N. Nazarbayev in the fight against nuclear non-proliferation since the 1990s.Randa Kassis thanked President Nazarbayev and Kazakhstan for agreeing to initiate political talks in 2015, after the appeal she launched, and for hosting negotiations at the end of 2016.',
        des3: 'Kazakhstan’s contribution to peace has been paramount.',
        icon: 'flaticon-team-work',
        ssImg1: sImgS3,
        ssImg2: sImgS3,
    },
    {
        Id: '4',
        screens: sImgS4,
        title: 'A “road map” to peace.. will be presented over the next few weeks',
        slug: 'road-map-to-peace',
        author: 'john doe',
        date: '02/05/2024',
        description: `The Syrian opposition on Monday presented a “road map” to peace, including steps to be taken ahead of an election and guidelines for a plan to write the country’s post-war constitution. The document, drawn up by representatives of the opposition invited to Rome by the Catholic Sant’Egidio community, will be presented to key players in the conflict — from Russia to Turkey and the EU — over the next few weeks.`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-bus',
        ssImg1: sImgS4,
        ssImg2: sImgS4,
    },
    {
        Id: '5',
        screens: sImgS5,
        title: 'Trump’s son meets Randa Kassis',
        slug: 'trumps-son-meets-randa-kassis',
        author: 'john doe',
        date: '02/05/2024',
        description: `Press Tv               26/11/2016

Reports suggest Trump’s eldest son, Donald Trump Junior, met the pro-Russia Syrian politician Randa Kassis nearly a month ahead of the November 8 election.

`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS5,
        ssImg2: sImgS5,
    },
    {
        Id: '6',
        screens: sImgS6,
        title: 'Is there any hope for the Syria talks?',
        slug: 'is-there-any-hope-for-the-syria-talks',
        author: 'john doe',
        date: '02/05/2024',
        description: `      30/01/2016


Opposition groups held multiple meetings in Riyadh to decide who would attend the talks. And the international community repeatedly debated the fate of Syrian President Bashar al-Assad, all in anticipation of negotiations that were scheduled to begin in January.
`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        des4:`Those talks were delayed once but have now begun. But without main members of the opposition.`,
        icon: 'flaticon-job',
        ssImg1: sImgS6,
        ssImg2: sImgS6,
    },
    {
        Id: '7',
        screens: sImgS7,
        title: 'In Syria: Revolt and Secularity',
        slug: 'in-syria-revolt-and-secularity',
        author: 'john doe',
        date: '02/05/2024',
        description: ` The recent protests in several Arab countries have aroused great hope among women. These mass protests were considered at the time the beginning of an awareness, with the final goal being the attainment of a change. These results had been preceded by a phase of several...`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS7,
        ssImg2: sImgS7,
    },
    {
        Id: '8',
        screens: sImgS8,
        title: 'Turkey: is it still our ally?',
        slug: 'turkey-is-it-still-our-ally',
        author: 'john doe',
        date: '02/05/2024',
        description: `Published in Le figaro, 24/09/2014 By Fabien Baussart, Alexandre del Valle, Randa Kassis In order to fight the Islamic State, that totalitarian monstrosity that intends to subdue the Middle East and in the end, the entire world, ten countries in the region have signed the « Jeddah Communique...`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS8,
        ssImg2: sImgS8,
    },
    {
        Id: '9',
        screens: sImgS9,
        title: 'Syria’s Identity: Variety in Minorities',
        slug: 'syrias-identity-variety-in-minorities',
        author: 'john doe',
        date: '02/05/2024',
        description: `In the face of the fierce battle that the regime is waging against its people, it has become apparent that the opposition leadership is unable to face this movement. There are many reasons for this...`,
        des2: 'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3: 'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon: 'flaticon-job',
        ssImg1: sImgS9,
        ssImg2: sImgS9,
    },
    {
        Id: '10',
        screens: sImgS1,
        title: 'MEDays 2015',
        slug: 'medays2015',
        author: 'Tangier Morocco ',
        date: '14/11/2015',
        description: `Randa Kassis at the forum MEDays, Tangier Morocco.
MEDays 2015: The Fail of the Global War against the Terrorism.`,
        des2: ``,
        des3: ``,
        icon: 'flaticon-credit',
        ssImg1: sImgS1,
        ssImg2: sImgS1,
    },
    {
        Id: '11',
        screens: sImgS2,
        title: 'Geneva Peace Talks 2014',
        slug: 'geneva=peace=talks',
        author: 'Let’s talk peace!',
        date: '19/09/2014',
        description: `This year’s theme is “Let’s Talk Peace!”. The Geneva Peace Talks 2014 will highlight how communication tools, techniques, and channels can contribute to resolving conflict. Speakers will reflect on how they have personally overcome the challenges of communication in their involvements in peacebuilding, communications, peace negotiations, as well as discussions with perpetrators of violence and underrepresented groups to build lasting peace.

Coming from violent and divided environments as well as backgrounds in science, art, business, and more, speakers will share their personal stories of peace and how communication has made a difference in their peacebuilding efforts.

The Geneva Peace Talks are a public event organized on the occasion of the International Day of Peace in a partnership between the United Nations Office at Geneva, Interpeace and the Geneva Peacebuilding Platform.`,
        des2: '.',
        des3: '',
        icon: 'flaticon-job',
        ssImg1: sImgS2,
        ssImg2: sImgS2,
    },
    {
        Id: '12',
        screens: sImgS3,
        title: 'Law and Religion',
        slug: 'law-and-religion',
        author: 'UNIVERSITY OF ROME, ITALY ',
        date: '02/05/21/06/2013',
        description: `The relationship between law and religion is an actual and thorny problem, especially in the light of the development, in the Euro-Mediterranean area, of multiple ethical and religious feelings which have generated more and more complex and elaborate, as a result of the relevant migratory flows characterizing the present articulated relations. All this is the result of the relevant migrations which characterize the present situation.

The debate over the space to acknowledge juridical expectations deriving from the encounter/confrontation of different religions and cultures regards, among other issues, international, constitutional, regional and maritime laws.

Starting from theoretical, philosophical and legal considerations, this workshop aims at analyzing the links between the religious and juridical aspects of Mediterranean and European States.

The first session will examine the connection between religion and law in the light of the revolutions in North-African countries, with a special attention to gender and childhood rights.

The second session will be instead dedicated to the analysis of the concepts of pluralism and freedom of worship in the context of European public space, with a special attention to the experiences of several Euro-Mediterranean States, Italy among them.`,
        des2: '',
        des3: '',
        icon: 'flaticon-team-work',
        ssImg1: sImgS3,
        ssImg2: sImgS3,
    },
    {
        Id: '50',
        screens: sImgS3,
        title: 'Political Beliefs',
        slug: 'political-beliefs',
        author: 'UNIVERSITY OF ROME, ITALY ',
        date: '02/05/21/06/2013',
        description: `My political beliefs have been shaped by a lifetime of intellectual rebellion, activism, and a deep engagement with history, philosophy, and the realities of power. I reject all forms of ideological rigidity, recognising that dogma—no matter its source—inevitably leads to oppression.`,
        des2: 'At my core, I believe in the primacy of individual freedom over collective identity. I have dedicated my life to challenging the dogmas that shape the Arab world, advocating for a state that protects personal freedoms rather than imposing outdated moral codes.',
        des3: 'Yet, I am not naive about politics. The pursuit of a utopian system is futile; history has shown that every ideological promise of salvation ends in disillusionment. Instead, I believe in pragmatism—seeking solutions that work, adapting to realities without sacrificing core principles. Politics, like life, is fluid, and those who fail to adapt are doomed to irrelevance',
        des3: 'Yet, I am not naive about politics. The pursuit of a utopian system is futile; history has shown that every ideological promise of salvation ends in disillusionment. Instead, I believe in pragmatism—seeking solutions that work, adapting to realities without sacrificing core principles. Politics, like life, is fluid, and those who fail to adapt are doomed to irrelevance',
        icon: 'flaticon-team-work',
        ssImg1: sImgS3,
        ssImg2: sImgS3,
    },
    
];
export default blogs;
